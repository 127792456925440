import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API} from '../Configuration';
import { useAuth } from "../Auth.js";

import moment from 'moment';

const Whistleblow = (props) => {
    const { t, i18n } = useTranslation();

    const auth = useAuth();
    const token = localStorage.getItem('t');

    // set locale for moment
    moment.locale(i18n.language);

    const [feedback, setFeedback] = React.useState(null);
    const [whistleblow, setWhistleblow] = React.useState(null);
    const [message, setMessage] = React.useState("");

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const sendMessage = () => {
        const formData = new FormData();
        formData.append('id', feedback.id);
        formData.append('message', message);
        formData.append('sender', "sender");
        formData.append('language', i18n.language);
        axios.post(API + '/whistleblow/customermessage', formData)
        .then(function (response) {
            if (response.data.success && response.data.messages) {
                console.log(response.data.messages)
                setMessage("")
                setWhistleblow({
                    ...whistleblow,
                    "messages" : response.data.messages
                });
            }
        })
        .catch(function (error) {
        });
    }
    

    // clone feedback object
    useEffect(() => {
        if (props.code) {
            setFeedback(JSON.parse(JSON.stringify(props.feedback)))

            axios.get(API + '/whistleblow/get?code='+props.code)
            .then(function (response) {
                if (response.data.success) {
                    setWhistleblow(response.data.whistleblow)
                }
            })
            .catch(function(error) {
            });
        }
	}, [props.code])

	return (
        <>
            {feedback &&
                <>
                    <div className="row">
                        <div className="col-12 mt-4 pb-4">

                            {whistleblow &&
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>{t('feedback.whistleblow-title')}</h4>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 pt-3">

                                            <div class="chat-bubble original">{whistleblow.originalmessage}
                                                {whistleblow.hasAttachment &&
                                                    <>
                                                        <br/><small><a href={API + "/whistleblow/attachment?wbid="+whistleblow.id} target="_blank">{whistleblow.attachmentName}</a></small>
                                                    </>
                                                    }
                                                <br/><small className="text-muted">{moment(whistleblow.sent).fromNow()}, {t('feedback.whistleblow-message-sender')}</small>
                                            </div>

                                            {(whistleblow.messages) && 
                                                <>
                                                    {whistleblow.messages.map((message, index) =>
                                                        <div key={message + '_' + index} className={message.sender=='admin' ? "chat-bubble me" : "chat-bubble"}>{message.message}<br/><small className="text-muted">{moment(message.sent).fromNow()}, {message.sender=='admin' ? t('feedback.whistleblow-message-admin') : t('feedback.whistleblow-message-sender')}</small></div>
                                                    )}
                                                </>
                                            }
                                            <form>
                                                <p className="mb-1">{t('feedback.whistleblow-message-title')}</p>
                                                <div className="mb-3">
                                                    <textarea className="form-control" id="sendMessage" rows="4" value={message} onChange={handleMessageChange}></textarea>
                                                </div>
                                                <button className="btn btn-primary" type="button" onClick={sendMessage}>{t('feedback.whistleblow-message-button')}</button>
                                            </form>

                                        </div>
                                    </div>

                                </>
                            }
                            
                        </div>
                    </div>
                    
                </>
            }
        </>
    );
}

export default Whistleblow;