import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {API, URL} from '../Configuration';
import { useAuth } from "../Auth.js";

import FeedbackStats from "./FeedbackStats"
import Whistleblow from "./Whistleblow";

const EditFeedback = (props) => {
    const { t, i18n } = useTranslation();

    const auth = useAuth();
    const token = localStorage.getItem('t');

    const [feedback, setFeedback] = React.useState(null);
    const [formModified, setFormModified] = React.useState(false);
    const [formValid, setFormValid] = React.useState(true);

    const [tab, setTab] = React.useState('stats');

    const toFileName = (filename) => {
        return filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    }

    const handleChange = (event) => {
        setFormModified(true)
        let field = (event.target.type === 'radio') ? event.target.name : event.target.id;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        setFeedback({
			...feedback,
			[field]: value
		});
    }

    const saveFeedback = () => {
        let isValid = true;
        if (feedback.name=="") {
            isValid = false;
        }

        if (isValid) {
            // post user data to backend
            setFormValid(true)
            axios.post(API + '/feedbacks/save', feedback, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setFormValid(true)
                    setFormModified(false)
                    setFeedback(response.data.feedback)
                    if (props.reloadFeedbacks) {
                        props.reloadFeedbacks()
                    }
				}
            })
            .catch(function (error) {
            });


        } else {
            setFormValid(false)
        }
        
    }

    const clearFeedback = () => {
        setFormModified(false)
        setFeedback(null)
        if (props.clearFeedback) {
            props.clearFeedback()
        }
    }

    const deleteFeedback = () => {
        if (confirm(t('feedbacks.confirmdelete'))) {
            axios.get(API + '/feedbacks/delete?id=' + feedback.id, {headers: { Authorization: `Bearer ${token}`} })
            .then(function (response) {
                if (response.data.success) {
                    setFormModified(false)
                    setFeedback(null)
                    if (props.reloadFeedbacks) {
                        props.reloadFeedbacks()
                    }
                }
            })
            .catch(function(error) {
            });
        }
    }


    // clone user object
    useEffect(() => {
        if (props.feedback) {
            if (!formModified) {
                setFormModified(false)
                let newfeedback = JSON.parse(JSON.stringify(props.feedback));
                // if new feedback and masteruser select user then put id manually
                if (!newfeedback.userid && props.selectedUserId) {
                    newfeedback.userid = props.selectedUserId
                }
                setFeedback(newfeedback)
            } else {
                if (confirm(t('users.confirmidmodified'))) {
                    setFormModified(false)
                    let newfeedback = JSON.parse(JSON.stringify(props.feedback));
                    // if new feedback and masteruser select user then put id manually
                    if (!newfeedback.userid && props.selectedUserId) {
                        newfeedback.userid = props.selectedUserId
                    }
                    setFeedback(newfeedback);
                }
            }
        } else {
            setFeedback(null);
        }
        
	}, [props.feedback])


	return (
        <div className="row">
            <div className="col-12">
                {feedback &&
                    <form className="pb-4">

                        <div className="d-flex justify-content-between">
                            {feedback.id 
                            ? <h3 dangerouslySetInnerHTML={{__html: t('feedbacks.title', { feedback: feedback }) }} />
                            : <h3>{t('feedbacks.title-new')}</h3>
                            }
                            {feedback.id &&
                            <button type="button" className="btn btn-outline-danger btn-sm ms-auto" onClick={deleteFeedback}>{t('feedbacks.delete')}</button>
                            }
                            
                        </div>
                        
                        {feedback.id &&
                        <ul className="nav nav-pills nav-fill mt-3">
                            <li className="nav-item">
                                {(feedback.type==="whistleblow")
                                ? <a className={(tab=="stats" ) ? "nav-link active" : "nav-link"} href="#" onClick={() => setTab('stats')}>{t('feedbacks.whistleblow')}</a>
                                : <a className={(tab=="stats" ) ? "nav-link active" : "nav-link"} href="#" onClick={() => setTab('stats')}>{t('feedbacks.stats')}</a>
                                }
                            </li>
                            <li className="nav-item">
                                <a className={(tab=="share" ) ? "nav-link active" : "nav-link"} href="#" onClick={() => setTab('share')}>{t('feedbacks.share')}</a>
                            </li>
                            <li className="nav-item">
                                <a className={(tab=="settings" ) ? "nav-link active" : "nav-link"} href="#" onClick={() => setTab('settings')}>{t('feedbacks.settings')}</a>
                            </li>
                        </ul>
                        }

                        {(tab=="stats" && feedback.id) &&
                            <>
                                {(feedback.type==="whistleblow")
                                ? <Whistleblow feedback={feedback} />
                                : <FeedbackStats feedback={feedback} />
                                }
                                
                            </>
                        }

                        {(tab=="share" && feedback.id) &&
                            <>
                                <div className="feedback-stats-container mt-4 pb-4 border-bottom">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>{t('feedbacks.share-title')}</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <h5>{t('feedbacks.share-link')}</h5>
                                            <a href={URL + "" + feedback.id} target="_blank">{URL + "" + feedback.id}</a>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <h5>{t('feedbacks.share-qrcode')}</h5>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-5">
                                            <img src={API + "/feedbacks/qrcode/png?id=" + feedback.id} alt={"QRCode for " + feedback.name} width="100%" />
                                        </div>
                                        <div className="col-7">
                                            <a className="btn btn-link" href={API + "/feedbacks/qrcode/png?id=" + feedback.id} target="_blank">{t('feedbacks.share-download-png')}</a><br/>
                                            <a className="btn btn-link" href={API + "/feedbacks/qrcode/svg?id=" + feedback.id} target="_blank">{t('feedbacks.share-download-svg')}</a><br/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {(tab=="settings" || !feedback.id) &&
                            <>
                                <div className="mt-3 mb-3">
                                    <label htmlFor="name" className="form-label">{t('feedbacks.name')} *</label>
                                    <input id="name" type="text" name="name" className={(!formValid && feedback.name=="" ) ? "form-control is-invalid" : "form-control"} value={feedback.name} onChange={handleChange}/>
                                    <div className="form-text">{t('feedbacks.name-help')}</div>
                                </div>

                                <div className="mt-3 mb-3">
                                    <label htmlFor="type" className="form-label">{t('feedbacks.type')} *</label>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="type" id="type-both" value="both" checked={(feedback.type=='both')} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="type-both">{t('feedbacks.type-both')}</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="type" id="type-feedback" value="feedback" checked={(feedback.type=='feedback')} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="type-feedback">{t('feedbacks.type-feedback')}</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="type" id="type-message" value="message" checked={(feedback.type=='message')} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="type-message">{t('feedbacks.type-message')}</label>
                                    </div>
                                    {((auth && auth.user) && (auth.user.whistleblow) || ((auth.user.og || auth.user.rs))) &&
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="type" id="type-whistleblow" value="whistleblow" checked={(feedback.type=='whistleblow')} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="type-whistleblow">{t('feedbacks.type-whistleblow')}</label>
                                    </div>
                                    }

                                    <div className="form-text">{t('feedbacks.type-help')}</div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">{t('feedbacks.email')}</label>
                                    <input id="email" type="email" name="email" className="form-control" value={feedback.email} onChange={handleChange}/>
                                    <div className="form-text">{t('feedbacks.email-help')}</div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="question" className="form-label">{t('feedbacks.question')}</label>
                                    <input id="question" type="text" name="question" className="form-control" value={feedback.question} onChange={handleChange}/>
                                    {(feedback.type=='whistleblow') 
                                    ? <div className="form-text">{t('feedbacks.question-help')} <i>{t('feedback.default-question-whistleblow')}</i></div>
                                    : <div className="form-text">{t('feedbacks.question-help')} <i>{t('feedback.default-question')}</i></div>
                                    }
                                </div>

                                {(feedback.type!=='message' && feedback.type!=='whistleblow') &&

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="mb-32">
                                                <label htmlFor="worst" className="form-label">{t('feedbacks.option-worst')}</label>
                                                <input id="worst" type="text" name="worst" className="form-control" value={feedback.worst} onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-32">
                                                <label htmlFor="best" className="form-label">{t('feedbacks.option-best')}</label>
                                                <input id="best" type="text" name="best" className="form-control" value={feedback.best} onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-text">{t('feedbacks.options-help')} <i>{t('feedback.default-option-worst')} / {t('feedback.default-option-best')}</i></div>
                                        </div>
                                        
                                    </div>

                                }    

                                {/*
                                <div className="mb-4">
                                    <div className="form-check">
                                        <input id="message" className={(!formValid && !feedback.message ) ? "form-check-input is-invalid" : "form-check-input"} type="checkbox" checked={feedback.message} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="message">{t('feedbacks.message')}</label>
                                    </div>
                                    <div className="form-text">{t('feedbacks.message-help')}</div>
                                </div>
                                */}

                                <div className="d-flex justify-content-between mt-5">
                                    <button type="button" className="btn btn-primary" onClick={saveFeedback}>{t('feedbacks.save')}</button>
                                    <button type="button" className="btn btn-link ms-auto" onClick={clearFeedback}>{t('feedbacks.cancel')}</button>
                                </div>
                            </>
                        }

                        
                    </form>

                }
            </div>
        </div>
	);
}

export default EditFeedback;